import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import router from 'next/router';
import Image from 'next/image';
import { BasicButton, IconButton, BlueButton } from '../Button';
import { ButtonGroup, Modal } from 'react-bootstrap';
import { Color } from '../../constants';
import success from '../../public/success.svg';
import error from '../../public/error.svg';
import confirm from '../../public/confirm.svg';
import normal from '../../public/normal.svg';

export const EmailLogin: FC = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigateSignup = () => router.push('signup/terms-acceptance');
  const navigateLogin = () => router.push('login/email-login');

  return (
    <>
      <IconButton icon="mail" bgcolor="#48c986" onClick={handleShow} />
      <Modal style={{ top: 'calc(100% - 11em)' }} show={show} onHide={handleClose}>
        <ButtonGroup vertical>
          <BasicButton style={{ color: 'gray', alignSelf: 'center', fontSize: '12px' }}> 이메일 로그인</BasicButton>
        </ButtonGroup>
        <BasicButton
          onClick={navigateSignup}
          style={{
            borderTop: 'solid 1px lightgrey',
            borderBottom: 'solid 1px lightgrey',
            borderRadius: 0,
            color: Color.blue,
          }}
        >
          이메일로 가입하기
        </BasicButton>
        <BasicButton onClick={navigateLogin} style={{ color: Color.blue, alignSelf: 'center' }}>
          이메일로 로그인하기
        </BasicButton>
      </Modal>
    </>
  );
};

interface Props {
  title?: ReactNode;
  children?: ReactNode;
  status?: string;
  path?: string;
  isModalOpen?: boolean;
  setModalOpen?: Dispatch<SetStateAction<boolean>>;
}

export const NotifyModal: FC<Props> = ({ title, children, status, path, isModalOpen, setModalOpen }) => {
  const handleClose = () => {
    if (setModalOpen) {
      setModalOpen(false);
    }
  };

  const navigate = () => {
    if (!path) {
      handleClose();
    } else {
      router.push(path);
    }
  };

  const statusMap: { [index: string]: string } = {
    success,
    error,
    confirm,
    normal,
  };

  return (
    <Modal centered backdrop="static" show={isModalOpen} onHide={handleClose}>
      <div className="modal-container">
        {status && (
          <div className="modal-status">
            <Image src={statusMap[status]} alt="modal_image" width="80" height="80" />
          </div>
        )}
        {title && <div className="modal-content-title">{title}</div>}
        {children && <div className="modal-content-text">{children}</div>}
        <div className="modal-menu">
          <BlueButton onClick={navigate}>확인</BlueButton>
        </div>
      </div>
    </Modal>
  );
};
