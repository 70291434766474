import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { User } from 'swingby-shared';
import { isLoggedInVar, userVar } from '../../apollo/cache';
import { MUTATION_KAKAO_LOGIN } from '../../apollo/mutations';
import { IResult, IUserWithToken, DocumentType } from '../../apollo/types';
import { kakaoPhoneNumberFormatter } from '../../lib/kakao';

const useKakaoLogin = () => {
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [kakaoErrorModalOpen, setKakaoErrorModalOpen] = useState<boolean>(false);

  const [mutate] = useMutation<IResult<IUserWithToken>>(MUTATION_KAKAO_LOGIN, {
    onCompleted({ result }) {
      userVar(result.user as DocumentType<User>);
      isLoggedInVar(true);
      globalThis?.localStorage?.setItem('token', result.token);
      if (result.status === 'signIn') {
        router.push('/');
      } else {
        router.push('/signup/success');
      }
    },
    onError(err) {
      setErrorMessage(err.message);
      setKakaoErrorModalOpen(true);
    },
  });

  const kakaoLogin = () => {
    Kakao.Auth.login({
      success: async () => {
        try {
          const getUser = Kakao.API.request({
            url: '/v2/user/me',
            data: {
              property_keys: ['kakao_account.email', 'kakao_account.phone_number', 'kakao_account.profile.nickname'],
            },
          });
          const getTerms = Kakao.API.request({ url: '/v1/user/service/terms' });
          const [{ id, kakao_account }, terms] = await Promise.all([getUser, getTerms]);
          const { email, phone_number, profile } = kakao_account;

          const requiredTerm = !!terms.allowed_service_terms.filter(
            ({ tag }: { tag: string }) => tag === 'user_age_check',
          ).length;
          const marketingTerm = !!terms.allowed_service_terms.filter(({ tag }: { tag: string }) => tag === 'optional')
            .length;

          if (!email || !phone_number || !profile)
            throw new Error('카카오 로그인에 필요한 개인정보가 존재하지 않습니다.');

          mutate({
            variables: {
              kakaoId: String(id),
              email,
              cellPhone: kakaoPhoneNumberFormatter(phone_number),
              nick: profile.nickname,
              type: 'user',
              metaData: { signUpPath: 'kakao', requiredTerm, marketingTerm },
            },
          });
        } catch (err) {
          if (err instanceof Error) {
            setErrorMessage(err.message);
            setKakaoErrorModalOpen(true);
          }
        }
      },
      fail: ({ error_description }) => {
        setErrorMessage(error_description);
        setKakaoErrorModalOpen(true);
      },
    });
  };
  return { kakaoLogin, error: errorMessage, kakaoErrorModalOpen, setKakaoErrorModalOpen };
};

export default useKakaoLogin;
