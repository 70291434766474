import { gql } from '@apollo/client';
import { FRAGMENT_USER } from '../queries';

export const MUTATION_FIND_EMAILID_BY_CELLPHONE = gql`
  mutation findEmailIdByCellPhone($cellPhone: String!, $type: String!) {
    result: findEmailIdByCellPhone(cellPhone: $cellPhone, type: $type) {
      status
      emailId
    }
  }
`;

export const MUTATION_EMAILID_USER_PASSWORD = gql`
  mutation updatePasswordByCellPhone($cellPhone: String!, $password: String!, $type: String!) {
    result: updatePasswordByCellPhone(cellPhone: $cellPhone, password: $password, type: $type) {
      status
    }
  }
`;

export const MUTATION_UPDATE_USERINFO = gql`
  mutation updateUser($uid: String, $record: UpdateOneUserInput!) {
    result: userUpdateByUid(filter: { uid: $uid }, record: $record) {
      record {
        ...userData
      }
      error {
        message
      }
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_ORDER = gql`
  mutation order($park: String, $cart: [OrderCartItemInputInput!]!, $saledAt: String!, $userPhotoUrl: String) {
    result: orderCreateOne(park: $park, cart: $cart, saledAt: $saledAt, userPhotoUrl: $userPhotoUrl) {
      uid
      userCoupons {
        coupon {
          uid
          name
          price
        }
      }
      userTickets {
        ticket {
          uid
          name
          price
        }
        metaData {
          parentPackage
        }
      }
      userProducts {
        product {
          id
          uid
          name
          price
        }
      }
      user {
        name
        emailId
        address
        cellPhone
      }
      cachedPrice
    }
  }
`;

export const MUTATION_RESERVATION = gql`
  mutation reservation($orderUid: String!, $record: UpdateManyUserTicketInput!) {
    result: updateReservation(orderUid: $orderUid, record: $record) {
      numAffected
    }
  }
`;

export const MUTATION_CHECK_PASSWORD = gql`
  mutation checkPassword($password: String!) {
    result: checkPassword(password: $password) {
      code
      success
    }
  }
`;

export const MUTATION_UPDATE_PASSWORD = gql`
  mutation updatePassword($password: String!) {
    result: updatePassword(password: $password) {
      code
      success
    }
  }
`;

export const MUTATION_CHECK_EMAILID_OVERLAP = gql`
  mutation findByEmailId($emailId: String!) {
    result: findByEmailId(emailId: $emailId) {
      status
    }
  }
`;

export const MUTATION_CHECK_CELLPHONE_OVERLAP = gql`
  mutation findByCellPhone($cellPhone: String!, $type: String!) {
    result: findByCellPhone(cellPhone: $cellPhone, type: $type) {
      status
    }
  }
`;

export const MUTATION_CREATE_TICKET_PAYMENT = gql`
  mutation createTicketPayment($record: CreateOnePaymentInput!) {
    result: paymentCreateOne(record: $record) {
      record {
        uid
        amount
        type
        payer {
          name
          email
          address
          cellPhone
        }
        order {
          userTickets {
            metaData {
              parentPackage
            }
            ticket {
              name
            }
          }
          userProducts {
            product {
              id
              name
              price
            }
          }
          uid
        }
        metaData {
          receipt_id
          approvementNum
        }
      }
    }
  }
`;

export const MUTATION_UPDATE_PAYMENTINFO = gql`
  mutation updatePaymentInfo($filter: FilterUpdateOnePaymentInput, $record: UpdateOnePaymentInput!) {
    result: paymentUpdateByOrderId(filter: $filter, record: $record) {
      record {
        uid
      }
    }
  }
`;

export const MUTATION_CANCEL_PAYMENT = gql`
  mutation cancelPayment($uid: String!, $isB2b: Boolean) {
    result: cancelPayment(uid: $uid, isB2b: $isB2b)
  }
`;

export const MUTATION_BAND_UPDATE_BY_NFCID = gql`
  mutation bandUpdateByNfcId($nfcId: String!, $token: String) {
    result: bandUpdateByNfcId(token: $token, nfcId: $nfcId) {
      code
      success
    }
  }
`;

export const MUTATION_SIGNUP = gql`
  mutation join(
    $email: String
    $password: String
    $cellPhone: String!
    $type: EnumUserType
    $emailId: String
    $nick: String
    $metaData: UserMetaDataInput
  ) {
    result: join(
      record: {
        email: $email
        cellPhone: $cellPhone
        password: $password
        type: $type
        metaData: $metaData
        emailId: $emailId
        nick: $nick
      }
    ) {
      recordId
    }
  }
`;

export const MUTATION_LOGIN = gql`
  mutation login($emailId: String!, $password: String!, $isWeb: Boolean) {
    result: loginWithEmail(emailId: $emailId, password: $password, isWeb: $isWeb) {
      token
      user {
        ...userData
      }
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_APPLE_SIGNUP = gql`
  mutation join(
    $email: String
    $cellPhone: String!
    $metaData: UserMetaDataInput
    $type: EnumUserType
    $appleId: String
    $nick: String
  ) {
    result: join(
      record: { email: $email, cellPhone: $cellPhone, type: $type, metaData: $metaData, appleId: $appleId, nick: $nick }
    ) {
      recordId
    }
  }
`;

export const MUTATION_APPLE_LOGIN = gql`
  mutation appleLogin($appleId: String!) {
    result: loginWithApple(appleId: $appleId) {
      token
      user {
        ...userData
      }
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_KAKAO_LOGIN = gql`
  mutation kakaoLogin(
    $email: String!
    $cellPhone: String!
    $kakaoId: String!
    $type: String!
    $nick: String
    $metaData: metaDataInput
  ) {
    result: loginWithKakao(
      email: $email
      cellPhone: $cellPhone
      kakaoId: $kakaoId
      type: $type
      nick: $nick
      metaData: $metaData
    ) {
      token
      status
      user {
        ...userData
      }
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_USER_WITHDRAWAL = gql`
  mutation userWithdrawal {
    result: userWithdrawal {
      success
    }
  }
`;

export const MUTATION_RESERVATION_COUNT = gql`
  mutation updateCount($date: String!, $parkUid: String!) {
    result: updateCount(date: $date, parkUid: $parkUid) {
      park {
        id
        name
      }
      timeTable
      reservedAt
      id
    }
  }
`;

export const MUTATION_RECEIVE_COUPON = gql`
  mutation userCouponCreateMission($couponUid: String!, $missionId: String!) {
    result: userCouponCreateMission(couponUid: $couponUid, missionId: $missionId) {
      id
      uid
    }
  }
`;

export const START_MISSION = gql`
  mutation createMission($record: CreateOneMissionInput!) {
    missionCreateOne(record: $record) {
      recordId
      record {
        attraction {
          name
          imageUrl
        }
        isComplete
        receivedUserCoupon {
          uid
        }
        createdAt
      }
    }
  }
`;

export const MUTATION_MARKETING_TERM = gql`
  mutation updateMarketingTerm {
    result: userUpdateMarketingTerm {
      ...userData
    }
  }
  ${FRAGMENT_USER}
`;

export const MUTATION_B2B_ORDER = gql`
  mutation createUserCouponOrder(
    $cart: [OrderCartItemInputInput]
    $saledAt: String!
    $B2BReservedNum: String
    $clientUid: String
  ) {
    result: orderCreateOne(cart: $cart, saledAt: $saledAt, B2BReservedNum: $B2BReservedNum, clientUid: $clientUid) {
      id
      uid
      userCoupons {
        coupon {
          uid
          name
          price
        }
        metaData {
          reservedNum
        }
      }
      user {
        id
        uid
        name
      }
      cachedPrice
    }
  }
`;

export const MUTATION_CREATE_B2B_PAYMENT = gql`
  mutation createB2BPayment($record: CreateOnePaymentInput!) {
    result: paymentCreateOne(record: $record) {
      record {
        uid
        amount
        type
        payer {
          name
          email
          address
          cellPhone
        }
        order {
          uid
          userCoupons {
            uid
            coupon {
              uid
              name
              price
            }
          }
        }
        metaData {
          receipt_id
          approvementNum
        }
      }
    }
  }
`;

export const CHECK_COMPLETE = gql`
  mutation update($bandUid: String!, $missionId: String!) {
    result: missionUpdateComplete(bandUid: $bandUid, missionId: $missionId) {
      id
      isComplete
    }
  }
`;

export const UPDATE_RECORDS = gql`
  mutation updateSensorActivity($bandUid: String!) {
    result: sensorActivityUpdateMany(bandUid: $bandUid) {
      zone
      createdAt
      measureList
    }
  }
`;

export const MUTATION_USERCOUPON = gql`
  mutation enterByUserCoupon($uid: [String]!, $park: String!) {
    result: userCouponEnter(uid: $uid, park: $park) {
      id
      uid
      usedAt
      status
      usedCount
      remainLifeCount
      metaData {
        usedPark {
          uid
          name
        }
      }
    }
  }
`;
