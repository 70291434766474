import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AppleLoginButton, KakaoLoginButton } from '../../components/Button';
import { ContainerFullHeight, Spacer } from '../../components/Container';
import { RowCentered } from '../../components/Row';
import styled from 'styled-components';
import { DividerLabel, Text, UnderlineTextLinked } from '../../components/Typography';
import { IntroImage } from '../../components/Image';
import { useRecoilState } from 'recoil';
import { signupAppleIdState } from '../../atoms';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { ModalStatus, WebViewEventType } from '../../constants';
import { NotifyModal } from '../../components/Modal';
import useWebView from '../../hooks/useWebView';
import useKakaoLogin from '../../operations/mutations/kakaoLogin';
import useAppleLogin from '../../operations/mutations/appleLogin';

export default function Home() {
  const router = useRouter();
  const { isIOS, isMobile } = useDeviceDetect();
  const { postMessage, response, type } = useWebView();
  const { appleLogin, error: appleError } = useAppleLogin();
  const { kakaoLogin, error: kakaoError, kakaoErrorModalOpen, setKakaoErrorModalOpen } = useKakaoLogin();
  const [appleErrorModalOpen, setAppleErrorModalOpen] = useState<boolean>(false);
  const [appleId, setAppleId] = useRecoilState(signupAppleIdState);

  useEffect(() => {
    if (appleId) setAppleId('');
  }, [isIOS]);

  useEffect(() => {
    if (appleError) setAppleErrorModalOpen(true);
  }, [appleError]);

  useEffect(() => {
    if (type === WebViewEventType.appleLogin) {
      const { sub: appleId, email } = response;
      appleLogin(appleId, email);
      return;
    }
  }, [type]);

  return (
    <>
      <ContainerFullHeight>
        <IntroImage />
        <LoginSection>
          <Spacer height="15px" />
          <KakaoLoginButton onClick={() => kakaoLogin()}>카카오톡으로 계속하기</KakaoLoginButton>
          <Spacer height="15px" />
          {isMobile && isIOS && (
            <AppleLoginButton
              onClick={() => {
                if (isMobile) return postMessage({ type: WebViewEventType.appleLogin });
              }}
            >
              Apple로 계속하기
            </AppleLoginButton>
          )}
          <Spacer height="15px" />
          <DividerLabel> 또는 </DividerLabel>
          <RowCentered style={{ display: 'flex', justifyContent: 'space-around' }}>
            <UnderlineTextLinked onClick={() => router.push('/login/email-login')}>이메일로 로그인</UnderlineTextLinked>
            <UnderlineTextLinked onClick={() => router.push('/signup/terms-acceptance')}>
              이메일로 가입
            </UnderlineTextLinked>
          </RowCentered>
          <div style={{ textAlign: 'center', marginTop: '70px' }} onClick={() => router.push('/')}>
            <Text style={{ fontSize: 12, opacity: 0.6, cursor: 'pointer' }}>다음에 가입할게요</Text>
          </div>
        </LoginSection>
      </ContainerFullHeight>
      <NotifyModal
        title="로그인 실패"
        status={ModalStatus.Error}
        isModalOpen={kakaoErrorModalOpen}
        setModalOpen={setKakaoErrorModalOpen}
      >
        {kakaoError}
      </NotifyModal>

      <NotifyModal
        title="로그인 실패"
        status={ModalStatus.Error}
        isModalOpen={appleErrorModalOpen}
        setModalOpen={setAppleErrorModalOpen}
      >
        {appleError && appleError?.message}
      </NotifyModal>
    </>
  );
}

const LoginSection = styled.div`
  flex-shrink: 0;
  justify-content: center;
`;
